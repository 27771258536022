// source: qis/account/v1/account.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var qis_regional_v1_regional_pb = require('../../../qis/regional/v1/regional_pb.js');
goog.object.extend(proto, qis_regional_v1_regional_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.qis.account.v1.Account', null, global);
goog.exportSymbol('proto.qis.account.v1.AuthorizationAPI', null, global);
goog.exportSymbol('proto.qis.account.v1.Company', null, global);
goog.exportSymbol('proto.qis.account.v1.Token', null, global);
goog.exportSymbol('proto.qis.account.v1.User', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qis.account.v1.Company = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qis.account.v1.Company, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qis.account.v1.Company.displayName = 'proto.qis.account.v1.Company';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qis.account.v1.User = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qis.account.v1.User, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qis.account.v1.User.displayName = 'proto.qis.account.v1.User';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qis.account.v1.Token = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qis.account.v1.Token, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qis.account.v1.Token.displayName = 'proto.qis.account.v1.Token';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qis.account.v1.AuthorizationAPI = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qis.account.v1.AuthorizationAPI, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qis.account.v1.AuthorizationAPI.displayName = 'proto.qis.account.v1.AuthorizationAPI';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qis.account.v1.Account = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qis.account.v1.Account, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qis.account.v1.Account.displayName = 'proto.qis.account.v1.Account';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qis.account.v1.Company.prototype.toObject = function(opt_includeInstance) {
  return proto.qis.account.v1.Company.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qis.account.v1.Company} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.account.v1.Company.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    taxId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    account: (f = msg.getAccount()) && proto.qis.account.v1.Account.toObject(includeInstance, f),
    country: jspb.Message.getFieldWithDefault(msg, 5, 0),
    legalRepresentative: (f = msg.getLegalRepresentative()) && proto.qis.account.v1.User.toObject(includeInstance, f),
    users: (f = msg.getUsers()) && proto.qis.account.v1.User.toObject(includeInstance, f),
    website: jspb.Message.getFieldWithDefault(msg, 8, ""),
    createTime: (f = msg.getCreateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    enabled: jspb.Message.getBooleanFieldWithDefault(msg, 10, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qis.account.v1.Company}
 */
proto.qis.account.v1.Company.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qis.account.v1.Company;
  return proto.qis.account.v1.Company.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qis.account.v1.Company} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qis.account.v1.Company}
 */
proto.qis.account.v1.Company.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaxId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = new proto.qis.account.v1.Account;
      reader.readMessage(value,proto.qis.account.v1.Account.deserializeBinaryFromReader);
      msg.setAccount(value);
      break;
    case 5:
      var value = /** @type {!proto.qis.regional.v1.Country} */ (reader.readEnum());
      msg.setCountry(value);
      break;
    case 6:
      var value = new proto.qis.account.v1.User;
      reader.readMessage(value,proto.qis.account.v1.User.deserializeBinaryFromReader);
      msg.setLegalRepresentative(value);
      break;
    case 7:
      var value = new proto.qis.account.v1.User;
      reader.readMessage(value,proto.qis.account.v1.User.deserializeBinaryFromReader);
      msg.setUsers(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setWebsite(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateTime(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnabled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qis.account.v1.Company.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qis.account.v1.Company.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qis.account.v1.Company} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.account.v1.Company.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTaxId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAccount();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.qis.account.v1.Account.serializeBinaryToWriter
    );
  }
  f = message.getCountry();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getLegalRepresentative();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.qis.account.v1.User.serializeBinaryToWriter
    );
  }
  f = message.getUsers();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.qis.account.v1.User.serializeBinaryToWriter
    );
  }
  f = message.getWebsite();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCreateTime();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEnabled();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.qis.account.v1.Company.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qis.account.v1.Company} returns this
 */
proto.qis.account.v1.Company.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string tax_id = 2;
 * @return {string}
 */
proto.qis.account.v1.Company.prototype.getTaxId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.qis.account.v1.Company} returns this
 */
proto.qis.account.v1.Company.prototype.setTaxId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.qis.account.v1.Company.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.qis.account.v1.Company} returns this
 */
proto.qis.account.v1.Company.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Account account = 4;
 * @return {?proto.qis.account.v1.Account}
 */
proto.qis.account.v1.Company.prototype.getAccount = function() {
  return /** @type{?proto.qis.account.v1.Account} */ (
    jspb.Message.getWrapperField(this, proto.qis.account.v1.Account, 4));
};


/**
 * @param {?proto.qis.account.v1.Account|undefined} value
 * @return {!proto.qis.account.v1.Company} returns this
*/
proto.qis.account.v1.Company.prototype.setAccount = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.account.v1.Company} returns this
 */
proto.qis.account.v1.Company.prototype.clearAccount = function() {
  return this.setAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.account.v1.Company.prototype.hasAccount = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional qis.regional.v1.Country country = 5;
 * @return {!proto.qis.regional.v1.Country}
 */
proto.qis.account.v1.Company.prototype.getCountry = function() {
  return /** @type {!proto.qis.regional.v1.Country} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.qis.regional.v1.Country} value
 * @return {!proto.qis.account.v1.Company} returns this
 */
proto.qis.account.v1.Company.prototype.setCountry = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional User legal_representative = 6;
 * @return {?proto.qis.account.v1.User}
 */
proto.qis.account.v1.Company.prototype.getLegalRepresentative = function() {
  return /** @type{?proto.qis.account.v1.User} */ (
    jspb.Message.getWrapperField(this, proto.qis.account.v1.User, 6));
};


/**
 * @param {?proto.qis.account.v1.User|undefined} value
 * @return {!proto.qis.account.v1.Company} returns this
*/
proto.qis.account.v1.Company.prototype.setLegalRepresentative = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.account.v1.Company} returns this
 */
proto.qis.account.v1.Company.prototype.clearLegalRepresentative = function() {
  return this.setLegalRepresentative(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.account.v1.Company.prototype.hasLegalRepresentative = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional User users = 7;
 * @return {?proto.qis.account.v1.User}
 */
proto.qis.account.v1.Company.prototype.getUsers = function() {
  return /** @type{?proto.qis.account.v1.User} */ (
    jspb.Message.getWrapperField(this, proto.qis.account.v1.User, 7));
};


/**
 * @param {?proto.qis.account.v1.User|undefined} value
 * @return {!proto.qis.account.v1.Company} returns this
*/
proto.qis.account.v1.Company.prototype.setUsers = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.account.v1.Company} returns this
 */
proto.qis.account.v1.Company.prototype.clearUsers = function() {
  return this.setUsers(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.account.v1.Company.prototype.hasUsers = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string website = 8;
 * @return {string}
 */
proto.qis.account.v1.Company.prototype.getWebsite = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.qis.account.v1.Company} returns this
 */
proto.qis.account.v1.Company.prototype.setWebsite = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional google.protobuf.Timestamp create_time = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qis.account.v1.Company.prototype.getCreateTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qis.account.v1.Company} returns this
*/
proto.qis.account.v1.Company.prototype.setCreateTime = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.account.v1.Company} returns this
 */
proto.qis.account.v1.Company.prototype.clearCreateTime = function() {
  return this.setCreateTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.account.v1.Company.prototype.hasCreateTime = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional bool enabled = 10;
 * @return {boolean}
 */
proto.qis.account.v1.Company.prototype.getEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qis.account.v1.Company} returns this
 */
proto.qis.account.v1.Company.prototype.setEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qis.account.v1.User.prototype.toObject = function(opt_includeInstance) {
  return proto.qis.account.v1.User.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qis.account.v1.User} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.account.v1.User.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    email: jspb.Message.getFieldWithDefault(msg, 3, ""),
    password: jspb.Message.getFieldWithDefault(msg, 4, ""),
    document: jspb.Message.getFieldWithDefault(msg, 5, ""),
    picture: jspb.Message.getFieldWithDefault(msg, 6, ""),
    emergencyPhone: jspb.Message.getFieldWithDefault(msg, 7, ""),
    authorization: (f = msg.getAuthorization()) && proto.qis.account.v1.AuthorizationAPI.toObject(includeInstance, f),
    token: (f = msg.getToken()) && proto.qis.account.v1.Token.toObject(includeInstance, f),
    enabled: jspb.Message.getBooleanFieldWithDefault(msg, 10, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qis.account.v1.User}
 */
proto.qis.account.v1.User.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qis.account.v1.User;
  return proto.qis.account.v1.User.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qis.account.v1.User} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qis.account.v1.User}
 */
proto.qis.account.v1.User.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocument(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPicture(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmergencyPhone(value);
      break;
    case 8:
      var value = new proto.qis.account.v1.AuthorizationAPI;
      reader.readMessage(value,proto.qis.account.v1.AuthorizationAPI.deserializeBinaryFromReader);
      msg.setAuthorization(value);
      break;
    case 9:
      var value = new proto.qis.account.v1.Token;
      reader.readMessage(value,proto.qis.account.v1.Token.deserializeBinaryFromReader);
      msg.setToken(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnabled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qis.account.v1.User.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qis.account.v1.User.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qis.account.v1.User} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.account.v1.User.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDocument();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPicture();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getEmergencyPhone();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAuthorization();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.qis.account.v1.AuthorizationAPI.serializeBinaryToWriter
    );
  }
  f = message.getToken();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.qis.account.v1.Token.serializeBinaryToWriter
    );
  }
  f = message.getEnabled();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.qis.account.v1.User.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qis.account.v1.User} returns this
 */
proto.qis.account.v1.User.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.qis.account.v1.User.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.qis.account.v1.User} returns this
 */
proto.qis.account.v1.User.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string email = 3;
 * @return {string}
 */
proto.qis.account.v1.User.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.qis.account.v1.User} returns this
 */
proto.qis.account.v1.User.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string password = 4;
 * @return {string}
 */
proto.qis.account.v1.User.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.qis.account.v1.User} returns this
 */
proto.qis.account.v1.User.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string document = 5;
 * @return {string}
 */
proto.qis.account.v1.User.prototype.getDocument = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.qis.account.v1.User} returns this
 */
proto.qis.account.v1.User.prototype.setDocument = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string picture = 6;
 * @return {string}
 */
proto.qis.account.v1.User.prototype.getPicture = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.qis.account.v1.User} returns this
 */
proto.qis.account.v1.User.prototype.setPicture = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string emergency_phone = 7;
 * @return {string}
 */
proto.qis.account.v1.User.prototype.getEmergencyPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.qis.account.v1.User} returns this
 */
proto.qis.account.v1.User.prototype.setEmergencyPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional AuthorizationAPI authorization = 8;
 * @return {?proto.qis.account.v1.AuthorizationAPI}
 */
proto.qis.account.v1.User.prototype.getAuthorization = function() {
  return /** @type{?proto.qis.account.v1.AuthorizationAPI} */ (
    jspb.Message.getWrapperField(this, proto.qis.account.v1.AuthorizationAPI, 8));
};


/**
 * @param {?proto.qis.account.v1.AuthorizationAPI|undefined} value
 * @return {!proto.qis.account.v1.User} returns this
*/
proto.qis.account.v1.User.prototype.setAuthorization = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.account.v1.User} returns this
 */
proto.qis.account.v1.User.prototype.clearAuthorization = function() {
  return this.setAuthorization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.account.v1.User.prototype.hasAuthorization = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional Token token = 9;
 * @return {?proto.qis.account.v1.Token}
 */
proto.qis.account.v1.User.prototype.getToken = function() {
  return /** @type{?proto.qis.account.v1.Token} */ (
    jspb.Message.getWrapperField(this, proto.qis.account.v1.Token, 9));
};


/**
 * @param {?proto.qis.account.v1.Token|undefined} value
 * @return {!proto.qis.account.v1.User} returns this
*/
proto.qis.account.v1.User.prototype.setToken = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.account.v1.User} returns this
 */
proto.qis.account.v1.User.prototype.clearToken = function() {
  return this.setToken(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.account.v1.User.prototype.hasToken = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional bool enabled = 10;
 * @return {boolean}
 */
proto.qis.account.v1.User.prototype.getEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qis.account.v1.User} returns this
 */
proto.qis.account.v1.User.prototype.setEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qis.account.v1.Token.prototype.toObject = function(opt_includeInstance) {
  return proto.qis.account.v1.Token.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qis.account.v1.Token} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.account.v1.Token.toObject = function(includeInstance, msg) {
  var f, obj = {
    signedToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tokenType: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qis.account.v1.Token}
 */
proto.qis.account.v1.Token.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qis.account.v1.Token;
  return proto.qis.account.v1.Token.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qis.account.v1.Token} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qis.account.v1.Token}
 */
proto.qis.account.v1.Token.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSignedToken(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTokenType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qis.account.v1.Token.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qis.account.v1.Token.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qis.account.v1.Token} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.account.v1.Token.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSignedToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTokenType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string signed_token = 1;
 * @return {string}
 */
proto.qis.account.v1.Token.prototype.getSignedToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qis.account.v1.Token} returns this
 */
proto.qis.account.v1.Token.prototype.setSignedToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string token_type = 2;
 * @return {string}
 */
proto.qis.account.v1.Token.prototype.getTokenType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.qis.account.v1.Token} returns this
 */
proto.qis.account.v1.Token.prototype.setTokenType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qis.account.v1.AuthorizationAPI.prototype.toObject = function(opt_includeInstance) {
  return proto.qis.account.v1.AuthorizationAPI.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qis.account.v1.AuthorizationAPI} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.account.v1.AuthorizationAPI.toObject = function(includeInstance, msg) {
  var f, obj = {
    publicKey: jspb.Message.getFieldWithDefault(msg, 1, ""),
    privateKey: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qis.account.v1.AuthorizationAPI}
 */
proto.qis.account.v1.AuthorizationAPI.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qis.account.v1.AuthorizationAPI;
  return proto.qis.account.v1.AuthorizationAPI.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qis.account.v1.AuthorizationAPI} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qis.account.v1.AuthorizationAPI}
 */
proto.qis.account.v1.AuthorizationAPI.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPublicKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrivateKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qis.account.v1.AuthorizationAPI.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qis.account.v1.AuthorizationAPI.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qis.account.v1.AuthorizationAPI} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.account.v1.AuthorizationAPI.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPublicKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPrivateKey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string public_key = 1;
 * @return {string}
 */
proto.qis.account.v1.AuthorizationAPI.prototype.getPublicKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qis.account.v1.AuthorizationAPI} returns this
 */
proto.qis.account.v1.AuthorizationAPI.prototype.setPublicKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string private_key = 2;
 * @return {string}
 */
proto.qis.account.v1.AuthorizationAPI.prototype.getPrivateKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.qis.account.v1.AuthorizationAPI} returns this
 */
proto.qis.account.v1.AuthorizationAPI.prototype.setPrivateKey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qis.account.v1.Account.prototype.toObject = function(opt_includeInstance) {
  return proto.qis.account.v1.Account.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qis.account.v1.Account} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.account.v1.Account.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    balance: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qis.account.v1.Account}
 */
proto.qis.account.v1.Account.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qis.account.v1.Account;
  return proto.qis.account.v1.Account.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qis.account.v1.Account} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qis.account.v1.Account}
 */
proto.qis.account.v1.Account.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBalance(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qis.account.v1.Account.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qis.account.v1.Account.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qis.account.v1.Account} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.account.v1.Account.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBalance();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.qis.account.v1.Account.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qis.account.v1.Account} returns this
 */
proto.qis.account.v1.Account.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double balance = 2;
 * @return {number}
 */
proto.qis.account.v1.Account.prototype.getBalance = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.qis.account.v1.Account} returns this
 */
proto.qis.account.v1.Account.prototype.setBalance = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


goog.object.extend(exports, proto.qis.account.v1);
